<template>
    <div class="me-index">
        <div class="me-header primary">
            <div class="head-photo">
                <img src="../../assets/images/head_boy.png" alt="">
            </div>
            <div class="head-info">
                <h1 class="head-nickname">
                    {{userInfo.tmpFlag === '1' ? userInfo.realName : userInfo.userNameHide}}
                </h1>
                <p v-if="userInfo.tmpFlag === '1'">{{getMobileHide(userInfo.mobile)}}</p>
            </div>
        </div>
        <div class="me-body">
            <div class="me-menu-item">
                <router-link to="/userInfo">
                    <img src="@/assets/images/me_userInfo.png" alt="个人信息">
                    个人信息
                </router-link>
            </div>
            <div class="me-menu-item">
                <router-link to="/changePassword">
                    <img src="@/assets/images/me_password.png" alt="修改密码">
                    修改密码
                </router-link>
            </div>
            <div class="me-menu-item">
                <a @click="logoutConfirm = true">
                    <img src="@/assets/images/me_logout.png" alt="退出登录">
                    退出登录
                </a>
            </div>
            <div class="me-menu-item">
                <a @click="clearConfirm = true">
                    <img src="@/assets/images/clear_cache.png" alt="清除缓存">
                    清除缓存
                </a>
            </div>
        </div>
        
        <v-dialog
            v-model="logoutConfirm"
            persistent
            max-width="250"
        >
            <v-card>
                <v-card-title>
                    确定退出系统？
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="logoutConfirm = false"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue lighten-2"
                        text
                        @click="logout"
                    >
                        确定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="clearConfirm"
            persistent
            max-width="250"
        >
            <v-card>
                <v-card-title>
                    确定清除缓存？
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="clearConfirm = false"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue lighten-2"
                        text
                        @click="clear"
                    >
                        确定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {getUserInfo} from '@/service/service';
import {getMobileHide} from '@/util/util';
export default {
    name: 'MeIndex',
    data() {
        return {
            userInfo: {
                userName: '',
            },
            logoutConfirm: false,
            clearConfirm: false,
        }
    },

    beforeMount() {
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo) {
            this.userInfo = JSON.parse(userInfo)
        } else {
            this.$loading.show()
            getUserInfo().then(res => {
                this.$loading.hide();
                res.data.userNameHide = res.data.realName.substring(0, 8) + '...';
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                this.userInfo = res.data;
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            })
        }
    },

    methods: {
        logout() {
            this.$router.push('/login');
        },
        clear() {
            this.clearConfirm = false;
            this.$loading.show();
            const code = localStorage.getItem('code');
            const href = localStorage.getItem('href');
            // localStorage.removeItem('token');
            // localStorage.removeItem('userInfo');
            // localStorage.removeItem('loginCounter');
            // localStorage.removeItem('phone');
            // localStorage.removeItem('payUser');
            // localStorage.removeItem('idCard');
            localStorage.clear();
            localStorage.setItem('code', code);
            setTimeout(() => {
                this.$loading.hide();
                // this.$router.push('/');
                window.location.href = href;
            }, 1500);
        },
        getMobileHide,
    },
}
</script>

<style lang="scss" scoped>
.me-index {
    height: 100%;
    overflow-y: auto;
    .me-header {
        padding: 30px 20px;
        display: flex;
        align-items: center;
        .head-photo {
            width: 64px;
            height: 64px;
            margin-right: 20px;
        }
        .head-info {
            width: 1px;
            flex: 1;
            p {
                margin-bottom: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .head-nickname {
            font-size: 18px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .head-real-info {
            font-size: 13px;
        }
    }
    .me-body {
        display: flex;
        padding: 20px 10px;
        flex-wrap: wrap;
        .me-menu-item {
            width: 25%;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: #333333;
                font-size: 14px;
                img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 10px;
                }
            }
        } 
    }
} 
</style>